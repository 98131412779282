<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">공고 관리</h2>
      </div>
      <div class="body_section">
        <AnnounceListFilterBar
          :keywordDatas="keywordList"
          :defaultKeywordSelectedId="defaultKeywordSelectedId"
          :defaultKeywordInputText="defaultKeywordInputText"
          :statusList="statusList"
          :defaultStateId="defaultStateId"
          :defaultDates="defaultDates"
          @onClickSearch="onClickSearch"
        />

        <TableHead>
          <template v-slot:headLeft>
            <button
              v-if="getCanNewWriteProcess"
              type="button"
              class="btn_tertiary btn_medium"
              @click="onClickAdd"
            >
              <span class="ico_purchase ico_cross_w" />신규 등록
            </button>
          </template>
          <template v-slot:headRight>
            <SelectBoxPageRange
              :currentMaxRowCount.sync="size"
              @update:currentMaxRowCount="onChangeRowCount"
            />
          </template>
        </TableHead>
        <ListPage
          :list="dataList"
          :isHorizontalScroll="true"
          :scrollWidth="'1500px'"
          :currentPageIndex="currentPageIndex"
          :totalPageCount="totalPageCount"
          :maxRowCount="size"
          :maxPaginationCount="maxPaginationCount"
          @onChangePage="onChangePage"
        >
          <template v-slot:colgroup>
            <col style="width: 121px" />
            <!-- 진행상태 -->
            <col style="width: 89px" />
            <!-- 등록일 -->
            <col style="width: 136px" />
            <!-- 공고 번호 -->
            <col style="width: 192px" />
            <!-- 공고 명 -->
            <col style="width: 124px" />
            <!-- 분류 -->
            <col style="width: 136px" />
            <!-- 구매 요청번호 -->
            <col style="width: 100px" />
            <!-- 구매 품목 -->
            <col style="width: 138px" />
            <!-- 대상 업체 -->
            <col style="width: 89px" />
            <!-- 입찰 시작일 -->
            <col style="width: 89px" />
            <!-- 입찰 마감일 -->
            <col style="width: 162px" />
            <!-- 구매 담당자 -->
          </template>
          <template v-slot:tr>
            <th>진행 상태</th>
            <th>공고 등록일</th>
            <th>공고 번호</th>
            <th>입찰 공고명</th>
            <th>분류</th>
            <th>구매 요청번호</th>
            <th>구매 품목</th>
            <th>대상 업체</th>
            <th>입찰 시작일</th>
            <th>입찰 마감일</th>
            <th>구매 담당자</th>
          </template>
          <template v-slot:row="slotProps">
            <AnnounceListLine :rowData="slotProps.rowData" :statusList="statusList" />
          </template>
          <template v-slot:emptyList>
            <tr>
              <td colspan="10" class="td_empty">공고가 없습니다</td>
            </tr>
          </template>
        </ListPage>
      </div>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import AnnounceListFilterBar from "@/components/shared/announce/list/AnnounceListFilterBar";
import TableHead from "@/components/shared/TableHead";
import SelectBoxPageRange from "@/components/common/selectBox/SelectBoxPageRange";
import ListPage from "@/components/shared/ListPage";
import AnnounceListLine from "@/components/admin/announce/list/AnnounceListLine";

import { board } from "@/constants/options";
import PageMixin from "@/mixins/PageMixin";
import AnnounceListMixin from "@/mixins/announce/list/AnnounceListMixin";
import ApiService from "@/services/ApiService";

import LocalStorageManager from "@/LocalStorageManager";

export default {
  name: "AnnounceList",
  components: {
    PageWithLayout,
    AnnounceListFilterBar,
    TableHead,
    SelectBoxPageRange,
    ListPage,
    AnnounceListLine,
  },
  mixins: [PageMixin, AnnounceListMixin],
  data() {
    return {
      defaultKeywordSelectedId: "requestCid",
      // keywordList 옵션에 따라 computed로 분기.
    };
  },
  computed: {
    getCanNewWriteProcess() {
      if (!this.isShowAccessBtn(this.menuId.ANNOUNCE_WRITE)) return false;
      return LocalStorageManager.shared.getCanNewWriteProcess();
    },

    keywordList() {
      const arr = [
        { id: "requestCid", code: "requestCid", desc: "구매요청번호" },
        { id: "title", code: "title", desc: "입찰 공고명" },
        { id: "postCid", code: "postCid", desc: "입찰 공고번호" },
        { id: "companyName", code: "companyName", desc: "대상 업체" },
      ];

      // if (!this.isShowPurchaseJira)
      return arr;

      // const arrShowPurchaseJira = [
      //   { id: 'title', code: 'title', desc: '입찰 공고명' },
      //   { id: 'postCid', code: 'postCid', desc: '입찰 공고번호' },
      //   { id: 'companyName', code: 'companyName', desc: '대상 업체' },
      //   { id: 'requestCid', code: 'requestCid', desc: '구매요청번호' },
      // ];

      // return arrShowPurchaseJira;
    },
  },

  methods: {
    onClickAdd() {
      this.goWrite();
    },
    async goWrite() {
      const newNumber = await this.getNewAnnounceNumber();
      if (!newNumber) {
        return;
      }
      this.$router.push({
        path: `${this.$routerPath.ANNOUNCE_WRITE}/${newNumber}`,
      });
    },
    async getNewAnnounceNumber() {
      const path = this.$apiPath.CREATE_ANNOUNCE_NUMBER;
      const result = await ApiService.shared.getData(path);
      if (!result.data) return null;

      const newNumber = result.data.postCid;
      return newNumber;
    },
  },
};
</script>
